@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url('fonts/SourceSans3-VariableFont_wght.ttf') format('truetype');
  font-weight: 400, 600, 700;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400, 700, 900;
  font-display: swap;
}

* {
  margin: 0;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.myTitle {
  font-family: "Lato", sans-serif;
}
